<template>
  <footer class="sticky bottom-0 relative footer w-full bg-black p-2 pl-5 pr-5 text-white font-mono block">
      <table class="w-full">
        <tbody>
            <tr class="sm:float-right">
                <a href="https://goo.gl/maps/6SeWdi9U3GcPtN499" target="_blank" class="block sm:inline-block"><label class="cursor-pointer mr-5 hover:text-blue-800 focus:outline-none"><i class="far fa-map mr-2"></i>Bulevardul George Coşbuc, nr. 408, Galaţi</label></a>
                <label class="mr-5 focus:outline-none"><i class="far fa-paper-plane"></i> sowiens@yahoo.com</label>
                <label class="mr-5 focus:outline-none"><i class="fas fa-phone-volume mr-2"></i>+40 236 436 370</label>
            </tr>     
        </tbody>
      </table>   
  </footer>
</template>

<script>
export default {
  name: 'Header',
}
</script>
