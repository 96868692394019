<template>
  <div id="app">
    <Header class="z-20" />
    <router-view class="duration-700 delay-150 relative transform transition-all opacity-0 translate-y-12 ease-out" data-replace='{ "translate-y-12": "translate-y-0", "opacity-0": "opacity-100" }' />
    <Footer class="z-10" />
  </div>
</template>

<script>
import Header from './assets/General/Header.vue'
import Footer from './assets/General/Footer.vue'
export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
