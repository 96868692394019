<template>
  <div>
    <main>
      <div
        class="relative pt-16 pb-32 flex content-center items-center justify-center"
        style="min-height: 75vh;"
      >
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover bg-hero-pattern"
        >
          <div
            id="blackOverlay"
            class="w-full h-full absolute opacity-75 bg-black"
          ></div>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
              <div class="pr-12">
                <h1 class="text-white font-semibold text-5xl">
                  Sowiens
                </h1>
                <p class="content-center font-mono text-xl mt-5 text-gray-300">Principala noastră preocupare este să răspundem celor mai înalte exigenţe ale clienţilor!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
          style="height: 70px;"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-gray-300 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>
      <section class="pb-20 bg-gray-300 -mt-24">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap">
            <div v-for="(tlt, index) in descriptTitles" :key="tlt" class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <SlideBox class="h-52" v-bind:title = tlt v-bind:description = descriptText[index] />
            </div>
            
          </div>
          <div class="flex flex-wrap items-center mt-32">
            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <div
                class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100"
              >
                <i class="fas fa-user-friends text-xl"></i>
              </div>
              <h3 class="text-3xl mb-2 font-semibold leading-normal">
                De 30 de ani în slujba clienților noștri!
              </h3>
              <p
                class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700"
              >
              Felicitări tuturor cu prilejul aniversării a 30 ani de activitate a Companiei noastre!
              Ne propunem ca această companie să prospere, să fie mereu în top și să ne bucure pe noi toți cu rezultate remarcabile.
              </p>
            </div>
            <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg"
              >
                <img
                  alt="..."
                  src="/aniv.png"
                  class="w-full align-middle rounded-t-lg"
                />
              </div>
            </div>
          </div>
        </div>
      </section>     
    </main>
  </div>
</template>
<script>

import SlideBox from '../assets/Home/Slide-Box.vue'

export default {
  name: "landing-page",
  components: {
    SlideBox
  },
  data() {
    return {
      descriptTitles: ['Profesionalism & Excelenţă', 'Experienţă', 'Competenţă'],
      descriptText: ['Echipă de specialiști. Servicii și materiale la standarde europene.', 'Peste 100 de construcții predate la cheie, dintre care mai mult de 20 cu valoare mai mare de 1 milion de lei.', 'Soluții adaptate la cerințele beneficiarilor.']
    }
  },
  mounted() {
    setTimeout(function(){
        var replacers = document.querySelectorAll('[data-replace]');
        for(var i=0; i<replacers.length; i++){
            let replaceClasses = JSON.parse(replacers[i].dataset.replace.replace(/'/g, '"'));
            Object.keys(replaceClasses).forEach(function(key) {
                replacers[i].classList.remove(key);
                replacers[i].classList.add(replaceClasses[key]);
            });
        }
    }, 1);
  },
}
</script>
