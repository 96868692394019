<template>
  <header class="bg-black p-2 pl-5 pr-5 text-white font-mono shadow-xl sticky top-0 w-full">
      <table class="w-full">
        <tbody>
            <tr class="float-left">
                <i v-on:click="actionMenu" class="fas fa-bars mr-5 sm:hidden outline-none border-none"></i>
                <img height="30" width="30" class="inline-block bg-white rounded-2xl p-1 mr-2" src="icon-sow.png" />
                <label class="font-bold text-xl">Sowiens</label>
            </tr>
            <tr class="sm:float-right sm:block hidden">
                <router-link to="/" class="sm:mr-5 mr-3 hover:text-blue-800 focus:outline-none">Acasă</router-link>
                <router-link to="/project" class="sm:mr-5 mr-3 hover:text-blue-800 focus:outline-none">Servicii</router-link>
                <router-link to="/contact" class="sm:mr-5 mr-3 hover:text-blue-800 focus:outline-none">Contact</router-link>
            </tr>     
        </tbody>
      </table>
      <div class="bg-black sm:hidden" v-if="menuPanel">
        <router-link to="/" class="sm:mr-5 mr-3 hover:text-blue-800 focus:outline-none inline-block float-left">Acasa</router-link><br />
        <router-link to="/project" class="sm:mr-5 mr-3 hover:text-blue-800 focus:outline-none inline-block float-left">Servicii</router-link><br />
        <router-link to="/contact" class="sm:mr-5 mr-3 hover:text-blue-800 focus:outline-none inline-block float-left">Contact</router-link><br />
    </div>   
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      menuPanel: false
    }
  },
  methods: {
    actionMenu(){
      this.menuPanel = !this.menuPanel;
    }
  }
}
</script>
